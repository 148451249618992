<template>
  <div
    v-if="dialogVisible"
    class="status-dialog"
    :class="type == 1 ? 'loading-dialog' : ''"
  >
    <el-dialog
      v-model="dialogVisible"
      :top="top ? top : '36vh'"
      :close-on-click-modal="false"
      center
    >
      <img v-if="type == 1" src="@/assets/img/loading.png" class="loading" />
      <img v-if="type == 2" src="@/assets/img/succes.png" class="result-img" />
      <img v-if="type == 3" src="@/assets/img/error.png" class="result-img" />
      <div class="status-title">{{ title }}</div>
      <slot></slot>
      <template #footer v-if="!hideFooter">
        <el-button
          @click="lConfirm"
          type="primary"
          class="cancle"
          plain
          round
          :loading="isLoading"
          :style="{ width: btnWidth ? btnWidth : '140px' }"
          >{{ cancelText ? cancelText : "返回" }}</el-button
        >
        <template v-if="type != 1">
          <el-button
            class="confirm"
            @click="rConfirm"
            round
            :loading="isLoading"
            :style="{ width: btnWidth ? btnWidth : '140px' }"
            >{{ confirmText ? confirmText : "确认" }}</el-button
          >
        </template>
        <template> </template>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "status-dialog",
  // type 1loading 2succes 3error
  props: [
    "top",
    "btnWidth",
    "cancelText",
    "confirmText",
    "hideFooter",
    "type",
    "title",
  ],
  emits: ["rConfirm", "lConfirm"],
  setup(props, { emit }) {
    const dialogVisible = ref(false);
    const isLoading = ref(false);
    const rConfirm = () => {
      // dialogVisible.value = false;
      emit("rConfirm", "1333");
    };
    const lConfirm = () => {
      // dialogVisible.value = false;
      emit("lConfirm", "1333");
    };
    const show = () => {
      dialogVisible.value = true;
    };
    const close = () => {
      dialogVisible.value = false;
    };
    return {
      isLoading,
      show,
      close,
      rConfirm,
      lConfirm,
      dialogVisible,
    };
  },
};
</script>

<style lang="scss">
.loading-dialog {
   .el-dialog {
     width: 500px!important;
   }
}
.status-dialog {
  .el-dialog {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 100%;
      padding: 60px 50px 0 50px;
      text-align: center;
      @keyframes loading {
        to {
          transform: rotate(1turn);
        }
      }
      .loading {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
        animation: loading 1s linear infinite;
      }
      .result-img {
        width: 100px;
      }
      .status-title {
        color: var(--text-color);
        font-size: 16px;
        font-weight: 700;
      }
    }
    .el-dialog__footer {
      width: 100%;
      padding: 30px 20px 30px 20px;
      display: flex;
      justify-content: space-around;
      .cancle {
        &:hover {
          color: #ffffff;
          background-color: var(--theme-color);
        }
      }
    }
    .el-dialog__footer .el-button:nth-child(2) {
      margin-left: 0;
    }
  }
}
</style>
