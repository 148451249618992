<template>
  <w-dialog
    class="camera-container"
    ref="faceDialogRef"
    title="拍照录入"
    width="40%"
    top="25vh"
    confirmText="确认"
    @wConfirm="handleSure"
    @wCancel="handleCancel"
  >
    <div class="camera-content" v-loading="uploading">
      <video
        id="videoCamera"
        :width="videoWidth"
        :height="videoHeight"
        autoplay
      ></video>
      <canvas
        style="display: none"
        id="canvasCamera"
        :width="videoWidth"
        :height="videoHeight"
      ></canvas>
      <div v-if="imgSrc" class="camera-img">
        <img :src="imgSrc" alt />
      </div></div
  ></w-dialog>
</template>
<script>
import { ref, nextTick, computed } from "vue";
// 导入七牛云上传方法
import * as qiniu from "qiniu-js";
import { useStore } from "vuex";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const store = useStore();
    // 七牛云上传参数
    const qiniuData = computed(() => store.state.Config.qiniuData);
    const videoWidth = ref(180);
    const videoHeight = ref(180);
    const imgSrc = ref("");
    const canvas = ref(null);
    const ctx = ref(null);
    const video = ref(null);
    const uploading = ref(null);
    // const openVideo = ref(false);
    // onMounted(() => {
    //   openCamera();
    // });
    const faceDialogRef = ref(null);
    function openDialog() {
      imgSrc.value = "";
      faceDialogRef.value.show();
      nextTick(() => {
        openCamera();
      });
    }
    function closeDialog() {
      faceDialogRef.value.close();
    }
    // 调用权限（打开摄像头功能）
    function openCamera() {
      store.dispatch("getQiniuData");
      canvas.value = document.getElementById("canvasCamera");
      ctx.value = canvas.value.getContext("2d");
      nextTick(() => {
        video.value = document.getElementById("videoCamera");
        video.value.style.display = "block";
      });

      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          const getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      const constraints = {
        audio: false,
        video: {
          width: videoWidth.value,
          height: videoHeight.value,
          transform: "scaleX(-1)",
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in video.value) {
            video.value.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            video.value.src = window.URL.createObjectURL(stream);
          }
          video.value.onloadedmetadata = function () {
            video.value.play();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //  绘制图片（拍照功能）
    function takePhoto() {
      // canvas画图
      ctx.value.drawImage(
        video.value,
        0,
        0,
        videoWidth.value,
        videoHeight.value
      );
      // 获取图片base64链接
      const image = canvas.value.toDataURL("image/png");
      uploadImg(image);
    }
    function uploadImg(image) {
      uploading.value = true;
      const file = dataURLtoFile(image, "face");
      const token = qiniuData.value.token;
      const bucket = "cultural";
      const date = new Date();
      const nowDay =
        date.getFullYear() + "" + (date.getMonth() + 1) + "" + date.getDate();
      const filename = file.name;
      const index = filename.lastIndexOf(".");
      const suffix = filename.substr(index);
      const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
      const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
      const key = `${bucket}/${nowDay}/${new Date().getTime()}${random}-${randoms}${suffix}`;
      const putExtra = { fname: filename };
      const config = { useCdnDomain: true, region: qiniu.region.z2 };

      const observable = qiniu.upload(file, key, token, putExtra, config);
      // 上传开始
      observable.subscribe({
        next() {},
        error() {},
        complete(res) {
          uploading.value = false;
          imgSrc.value = res.standard_url; //赋值并预览图片
          video.value.style.display = "none";
          const data = {
            standard_url: res.standard_url,
            key: res.key,
          };

          emit("submit", data);
        },
      });
    }
    // 关闭摄像头
    function closeCamera() {
      video.value.srcObject.getTracks()[0].stop();
    }
    // // base64转文件，此处没用到
    function dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    function handleSure() {
      takePhoto();
    }
    function handleCancel() {
      imgSrc.value = "";
      video.value.style.display = "block";
    }
    return {
      videoWidth,
      videoHeight,
      imgSrc,
      openCamera,
      closeCamera,
      takePhoto,
      handleSure,
      faceDialogRef,
      openDialog,
      closeDialog,
      uploading,
      handleCancel,
    };
  },
};
</script>

<style lang="scss">
.camera-container {
  .el-dialog__body {
    display: flex;
    justify-content: center;
    .camera-content {
      width: 210px;
      height: 210px;
      box-sizing: border-box;
      border-radius: 10px;
      background-color: var(--theme-bg-color);
      display: flex;
      align-items: center;
      justify-content: center;
      .camera-img {
        width: 180px;
        height: 180px;
        display: inline-block;
        object-fit: cover;
      }
    }
  }
}
</style>
